import Vue from "vue";
import VueRouter from "vue-router";
// Routes
//登陆、注册相关路由
import auth from "@/router/routes/auth";
//展示页面相关路由
import front from "@/router/routes/front";
//系统管理相关路由
import admin from "@/router/routes/admin";
//异常相关路由
import errors from "@/router/routes/errors";
//自定义组件相关路由
import panel_group from "@/router/routes/panel-group";

//锦什坊项目路由
import projectJsf from "@/router/routes/project-jsf";
//海境界2期项目路由
import projectHjj2 from "@/router/routes/project-hjj2";

//其他建筑标准首页
import construction from "./routes/construction";
import {getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn} from "@/libs/auth/utils";
import {canNavigate} from "@/libs/acl/routeProtection";
import store from "@/store";
import {refreshMenu} from "@/libs/utils/refreshMenu";
import {routerParams} from "@/libs/utils/routerParams";
import {refreshProject} from "@/libs/utils/refreshProject";

// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
	routes: [
		{
			path: "/",
			redirect: {name: "index-project-list"},
		},
		...auth,
		...front,
		...projectJsf,
		...projectHjj2,
		...admin,
		...panel_group,
		...errors,
		...construction,
		{
			path: "*",
			redirect: {name: "error-error404"},
		},
	],
});

router.beforeEach((to, _, next) => {
	const isLoggedIn = isUserLoggedIn();

	if (!canNavigate(to)) {
		// Redirect to log in if not logged in
		console.log("1_未登录");
		if (!isLoggedIn) return next({name: "auth-login"});

		// If logged in => not authorized
		console.log("2_认证失败");
		return next({name: "error-not-authorized"});
	}

	// Redirect if logged in
	if (to.meta.redirectIfLoggedIn && isLoggedIn) {
		console.log("3_已登陆-跳转到项目列表");
		const userData = getUserData();
		return next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
	}
	console.log("4_正常跳转");
	if (!to.params.title) {
		const currentRote = JSON.parse(localStorage.getItem("currentMenu"));
		store.commit("menus/UPDATE_MENU_CURRENT", currentRote);
	}
	return next();
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, _) => {
	// Remove initial loading
	const appLoading = document.getElementById("loading-bg");
	if (appLoading) {
		appLoading.style.display = "none";
	}
	/**
	 * 根据页面加载路由
	 * 张强 2021-08-09
	 */
	if (to.meta.layout !== "full") {
		if (to.path === "/project-list") {
			//项目列表页面时清空menus和已选择的项目
			localStorage.setItem("menus", JSON.stringify([]));
			localStorage.setItem("project_id", JSON.stringify(0));
			store.commit("projects/UPDATE_PROJECT_SELECTED", 0);
			store.commit("menus/UPDATE_MENU_TREE", []);
			store.commit("menus/UPDATE_MENU_CURRENT", to);
		} else {
			const currentRote = Object.assign({}, {name: to.name, params: Object.assign({}, to.params)});
			if (to.params.title) {
				localStorage.setItem("currentMenu", JSON.stringify(currentRote));
				store.commit("menus/UPDATE_MENU_CURRENT", currentRote);
			}
			const project_id = routerParams("project_id");
			if (project_id && parseInt(project_id)) {
				setTimeout(() => {
					refreshMenu(project_id).then(() => {
						console.log("menu_refreshed");
					});
					refreshProject(project_id).then(() => {
						console.log("project_refreshed");
					});
				}, 2000);
			}
		}
	}
});

export default router;
