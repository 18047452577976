<template>
	<div class="position-relative w-100 h-100">
		<b-table
			:bordered="option.option.bordered"
			:borderless="option.option.borderless"
			:fields="fields ? fields.filter((field) => field.show) : query_data.fields"
			:fixed="option.option.fixed"
			:foot-clone="option.option.footClone"
			:hover="option.option.hover"
			:items="query_data.temp_data"
			:small="option.option.small"
			:striped="option.option.striped"
			:tbody-tr-class="rowClass"
			class="w-100 h-100"
			responsive
			sticky-header
		>
		</b-table>
	</div>
</template>

<script>
	import store from "@/store";
	import { computed, reactive, toRefs } from "@vue/composition-api";

	export default {
		props: ["item", "preview"],
		setup(props) {
			const { item, preview } = props;
			const query_key = "query_" + item.query_id;
			const event = reactive({
				option: computed(() => {
					return item.options;
				}),
				fields: computed(() => {
					return item.options.fields;
				}),
				query_data: computed(() => {
					let query_data = store.state["panel-group"]["query-data-list"][query_key];
					if (!(query_data && query_data.temp_data)) {
						query_data = Object.assign({}, { temp_data: [] });
					}
					return query_data;
				}),
			});

			/**
			 * 根据数值设定行的颜色
			 */
			const rowClass = (row, type) => {
				const alert = event.option.alert;
				if (!alert.active) {
					return alert;
				}

				let value = parseFloat(row[alert.field]);
				let color = "success";

				if (value >= alert.lvl_2 && value <= alert.lvl_3) {
					color = "success";
				} else if (value >= alert.lvl_4 || value <= alert.lvl_1) {
					color = "danger";
				} else {
					color = "warning";
				}
				return "bg-light-" + color;
			};

			/**
			 * 自动滚动
			 */
			const autoscroll = () => {
				let parent = document.querySelector("#panel-" + item.panel_id + ".scroll-area");
				if (preview) {
					parent = document.querySelector("#panel-" + item.panel_id + "-preview.scroll-area");
				}
				let scroll_id = "scroll_" + item.panel_id;
				if (!window.scroll_notice) {
					window.scroll_notice = {};
				}
				if (window.scroll_notice[scroll_id]) {
					clearInterval(window.scroll_notice[scroll_id]);
				}
				let scroll = parseInt(event.option.scroll);
				if (scroll) {
					window.scroll_notice[scroll_id] = setInterval(
						function () {
							if (parent.scrollTop >= parent.scrollHeight - parent.offsetHeight) {
								parent.scrollTop = 0;
							} else {
								parent.scrollTop++;
							}
						},
						scroll ? scroll : 100
					);
				}
			};
			/**
			 * 停止自动滚动
			 */
			const stopscroll = () => {
				let scroll_id = "scroll_" + item.panel_id;
				clearInterval(window.scroll_notice[scroll_id]);
			};

			// watch(() => event.selectedFile, (file) => {
			// 	if (!!file) {
			// 		uploadFile(file)
			// 	}
			// })
			//更新store的数据
			// const update_store_options=()=>{
			// 	let options = store.state['panel-group']['panel-modal']['options']
			// 	options.list = event.list
			// 	store.commit('panel-group/UPDATE_PANEL_MODAL_OPTIONS', { ...options })
			// }
			return {
				...toRefs(event),
				stopscroll,
				rowClass,
			};
		},
	};
</script>
