/**
 * 登陆注册相关路由
 */
export default [
	{
		path: "/login",
		name: "auth-login",
		component: () => import("@/views/auth/Login.vue"),
		meta: {
			layout: "full",
			resource: "Auth",
			redirectIfLoggedIn: true,
		},
	},
	{
		path: "/register",
		name: "auth-register",
		component: () => import("@/views/auth/Register.vue"),
		meta: {
			layout: "full",
			resource: "Auth",
			redirectIfLoggedIn: true,
		},
	},
	{
		path: "/forgot-password",
		name: "auth-forgot-password",
		component: () => import("@/views/auth/ForgotPassword.vue"),
		meta: {
			layout: "full",
			resource: "Auth",
			redirectIfLoggedIn: true,
		},
	},
];
