import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import panelGroup from "@/views/panel-group/groupStoreModule.js";
import projects from "@/store/admin-project";
import users from "@/store/admin-user";
import menus from "@/store/admin-menu";
import jsf35 from "@/store/jsf35";
import hMenu from "@/store/h-menu";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		app,
		appConfig,
		verticalMenu,
		"panel-group": panelGroup,
		projects,
		users,
		menus,
		jsf35,
		hMenu,
		// "app-ecommerce": ecommerceStoreModule,
	},
	strict: process.env.DEV,
});
