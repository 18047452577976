export default [
	//海境界二期
	{
		path: "/:project_id/hjj2/demo1",
		name: "project-hjj2-demo1",
		component: () => import("@/views/front/hjj2/demo1"),
	},
	{
		path: "/:project_id/hjj2/demo2",
		name: "project-hjj2-demo2",
		component: () => import("@/views/front/hjj2/demo2"),
	},
]