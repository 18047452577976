<template>
	<swiper :options="swiperOptions" class="swiper-autoplay">
		<swiper-slide v-for="(swiperItem, swiperIndex) in list" :key="swiperIndex" class="w-100 h-100">
			<b-img :src="'https://' + swiperItem.url" class="w-100 h-100" />
		</swiper-slide>
		<div slot="pagination" class="swiper-pagination" />
		<!--		<div slot="button-next" class="swiper-button-next"/>-->
		<!--		<div slot="button-prev" class="swiper-button-prev"/>-->
	</swiper>
</template>

<script>
	import { Swiper, SwiperSlide } from "vue-awesome-swiper";

	import "swiper/css/swiper.css";
	import { onMounted, reactive, toRefs, watch } from "@vue/composition-api";

	export default {
		components: {
			Swiper,
			SwiperSlide,
		},
		props: ["item"],
		setup(props) {
			const { item } = props;
			const event = reactive({
				list: [],
				swiperOptions: {
					autoplay: {
						delay: 2500,
						disableOnInteraction: true,
					},
					pagination: {
						el: ".swiper-pagination",
						clickable: true,
					},
					// navigation: {
					// 	nextEl: '.swiper-button-next',
					// 	prevEl: '.swiper-button-prev',
					// },
				},
			});
			watch([() => item.options.list, () => item.options.options], (value) => {
				update_options();
			});
			onMounted(() => {
				update_options();
			});
			const update_options = () => {
				event.list = item.options.list;
				event.swiperOptions = Object.assign(event.swiperOptions, item.options.options);
			};

			return {
				...toRefs(event),
			};
		},
	};
</script>
