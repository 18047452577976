<template>
	<div>
		<b-form>
			<label>显示类型（type）</label>
			<b-form-group>
				<v-select
					v-model="option.type"
					:clearable="false"
					:options="params_type_list"
					:reduce="(text) => text.value"
					class="rounded"
					label="text"
				/>
			</b-form-group>

			<label>颜色（color）</label>
			<b-form-group>
				<v-select
					v-model="option.color"
					:class="'border-left-' + option.color"
					:clearable="false"
					:options="color_list"
					:reduce="(text) => text.value"
					class="rounded"
					label="text"
				>
					<template #option="{ text, value }">
						<span :class="'text-' + value"> {{ text }}</span>
					</template>
				</v-select>
			</b-form-group>

			<label>数据字段（field）</label>
			<b-form-group>
				<v-select
					v-model="option.field"
					:clearable="false"
					:options="field_list"
					class="rounded"
					label="text"
				/>
			</b-form-group>

			<label>数据计算方式（algorithm）</label>
			<b-form-group>
				<v-select
					v-model="option.algorithm"
					:clearable="false"
					:options="algorithm_list"
					:reduce="(text) => text.value"
					class="rounded"
					label="text"
				/>
			</b-form-group>

			<!-- <label>标题（title）</label>
      <b-form-group>
        <b-form-input v-model="option.title"/>
      </b-form-group> -->

			<label>说明文字（text）</label>
			<b-form-group>
				<b-form-input v-model="option.text" />
			</b-form-group>

			<label>图标（icon）</label>
			<b-form-group>
				<b-form-input v-model="option.icon" />
			</b-form-group>
		</b-form>
	</div>
</template>

<script>
import store from "@/store";
import { computed, reactive, toRefs, watch } from "@vue/composition-api";
import vSelect from "vue-select";

export default {
	name: "ParamsSettings",
	components: {
		vSelect,
	},
	props: ["item"],
	setup(props) {
		const { item } = props;
		const event = reactive({
			option: computed(() => {
				return item.options;
			}),
			query_data: computed(() => {
				const query_key = "query_" + item.query_id;
				let query_data =
					store.state["panel-group"]["query-data-list"][query_key];
				if (!(query_data && query_data.temp_data)) {
					query_data = Object.assign({}, { temp_data: [] });
				}
				return query_data;
			}),
			params_type_list: [
				{
					text: "纵向",
					value: "vertical",
				},
				{
					text: "横向",
					value: "horizontal",
				},
				{
					text: "面积图",
					value: "area-chart",
				},
				{
					text: "曲线图",
					value: "line-chart",
				},
			],
			color_list: computed(() => {
				let list = [];
				Object.keys(store.state.appConfig.colors).map((color) => {
					list.push({
						text: color,
						value: color,
					});
				});
				return list;
			}),
			field_list: computed(() => {
				return event.query_data.fields;
			}),
			algorithm_list: [
				{
					text: "最新值",
					value: "latest",
				},
				{
					text: "总和",
					value: "sum",
				},
				{
					text: "最大值",
					value: "max",
				},
				{
					text: "最小值",
					value: "min",
				},
				{
					text: "平均值",
					value: "avg",
				},
				{
					text: "中位数",
					value: "middle",
				},
			],
		});
		watch(
			[
				() => event.option.field,
				() => event.option.algorithm,
				() => event.query_data.temp_data,
			],
			() => {
				algorithm_result();
			},
			"deep"
		);
		//获取计算结果
		const algorithm_result = () => {
			const old_title = event.option.title;
			const old_data = event.option.data;
			const field = event.option.field;
			const algorithm = event.option.algorithm;
			const data_list = event.query_data.temp_data.map((data) =>
				parseFloat(!!data[field] ? data[field] : 0)
			);

			event.option.data = [
				{
					data: [...data_list],
					name: "",
				},
			];

			const length = data_list.length;
			let result = 0;
			if (algorithm === "latest") {
				result = data_list[length - 1];
			} else if (algorithm === "max") {
				result = Math.max(...data_list);
			} else if (algorithm === "min") {
				result = Math.min(...data_list);
			} else if (algorithm === "middle") {
				let arr = data_list;
				arr = arr.sort();
				//三目运算+IIFE写法
				length % 2 === 1
					? (() => {
							result = arr[(length - 1) / 2];
					  })()
					: (() => {
							result =
								(arr[length / 2] + arr[length / 2 - 1]) / 2;
					  })();
			} else {
				const sum = eval(data_list.join("+"));
				if (algorithm === "sum") {
					result = sum;
				}
				if (algorithm === "avg") {
					result = length > 0 ? parseFloat(sum / length) : 0;
				}
			}
			event.option.title = result.toFixed(2);
		};
		return {
			...toRefs(event),
		};
	},
};
</script>
