import Vue from "vue";
import store from "@/store";

/**
 * 通用确认弹窗
 * @param text
 * @param successCallback
 * @param cancelCallback
 * @param cancelButton 是否显示取消按钮(可选,默认显示)
 */
// 张强编写的showConfirm() 函数。
// 使用：
// showConfirm(
//  '确定删除?',
//  () => {......此处为通过代码},
//  () => {......此处为不通过代码}
// )
export function showConfirm(text = "", successCallback, cancelCallback = null, cancelButton = true) {
	Vue.swal({
		text: text,
		icon: "warning",
		showCancelButton: cancelButton,
		confirmButtonText: "确定",
		cancelButtonText: "取消",
		customClass: {
			content: store.state.appConfig.layout.skin === "dark" ? "text-grey" : "text-dark",
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-outline-secondary ml-1",
		},
		background: store.state.appConfig.layout.skin === "dark" ? "#283046" : "#f8f8f8",
		buttonsStyling: false,
	}).then((result) => {
		if (!!result.value) {
			successCallback();
		} else {
			if (cancelCallback !== null) {
				cancelCallback();
			}
		}
	});
}

Vue.prototype.$showConfirm = showConfirm;
