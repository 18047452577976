import useJwt from "@/libs/auth/jwt/useJwt";
import { datasource_settings_default, group_sidebar, group_topbar, panel_modal, query_default } from "@/views/panel-group/defaultSettings";

const axios = useJwt.axiosIns;

export default {
	namespaced: true,
	state: {
		sidebar: group_sidebar,
		topbar: group_topbar,
		"panel-modal": panel_modal,
		"query-modal": query_default,
		"datasource-modal": datasource_settings_default,
		// "query-temp-data": query_temp_data,
		"datasource-list": [],
		"query-list": [],
		"query-data-list": {},
	},
	getters: {},
	mutations: {
		// *===============================================---*
		// *--------- 数据源列表 ---------------------------------------*
		// *===============================================---*
		UPDATE_DATASOURCE_LIST(state, val) {
			state["datasource-list"] = val;
		},
		// *===============================================---*
		// *--------- 查询列表 ---------------------------------------*
		// *===============================================---*
		UPDATE_QUERY_LIST(state, val) {
			state["query-list"] = val;
		},
		// *===============================================---*
		// *--------- 查询数据列表 ---------------------------------------*
		// *===============================================---*
		UPDATE_QUERY_DATA_LIST(state, val) {
			state["query-data-list"] = val;
		},
		// *===============================================---*
		// *--------- 查询弹窗 ---------------------------------------*
		// *===============================================---*
		UPDATE_QUERY_MODAL(state, val) {
			state["query-modal"] = val;
		},
		// UPDATE_QUERY_TEMP_DATA(state, val) {
		// 	state["query-temp-data"] = val;
		// },
		// UPDATE_QUERY_TEMP_DATA_LOADING(state, val) {
		// 	state["query-temp-data"].loading = val;
		// },
		// *===============================================---*
		// *--------- 数据源弹窗 ---------------------------------------*
		// *===============================================---*
		UPDATE_DATASOURCE_MODAL(state, val) {
			state["datasource-modal"] = val;
		},

		// *===============================================---*
		// *--------- 侧边栏配置 ---------------------------------------*
		// *===============================================---*
		UPDATE_GROUP_SIDEBAR_SHOW(state, val) {
			state["sidebar"].show = val;
		},
		// *===============================================---*
		// *--------- 弹窗配置参数 ---------------------------------------*
		// *===============================================---*
		UPDATE_PANEL_MODAL(state, val) {
			state["panel-modal"] = val;
		},
		UPDATE_PANEL_MODAL_OPTIONS(state, val) {
			state["panel-modal"].options = val;
		},
		UPDATE_PANEL_MODAL_OPTIONS_TYPE(state, val) {
			state["panel-modal"].options_type = val;
		},
		// *===============================================---*
		// *--------- topbar配置参数 ---------------------------------------*
		// *===============================================---*
		UPDATE_TOPBAR_DATERANGE(state, val) {
			state["topbar"].daterange = val;
		},
		UPDATE_TOPBAR_LOCKED(state, val) {
			state["topbar"].locked = val;
		},
	},
	actions: {
		// *===============================================---*
		// *--------- Api-panel-group ---------------------------------------*
		// *===============================================---*
		fetchPanelGroupList(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.get("/api/panel/group", { params: payload })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		//获取指定仪表板详情
		fetchPanelGroup(ctx, { panel_group_uid }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/api/panel/group/${panel_group_uid}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		addPanelGroup(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post("/api/panel/group", payload)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		updatePanelGroup(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/api/panel/group/${payload.group_id}`, payload)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		removePanelGroup(ctx, { group_id }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/api/panel/group/${group_id}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		// *===============================================---*
		// *--------- Api-组件参数 ---------------------------------------*
		// *===============================================---*
		addPanelItem(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post("/api/panel/item", payload)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		updatePanelItem(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/api/panel/item/${payload.panel_id}`, payload)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		removePanelItem(ctx, { panel_id }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/api/panel/item/${panel_id}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		// *===============================================---*
		// *--------- Api-数据源 ---------------------------------------*
		// *===============================================---*
		fetchDatasourceList(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.get("/api/panel/datasource", { params: payload })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		//获取指定数据源详情
		fetchDatasource(ctx, { datasource_id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/api/panel/datasource/${datasource_id}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		//测试数据源连接
		testDatasource(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post("/api/panel/datasource/test", payload)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		addDatasource(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post("/api/panel/datasource", payload)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		updateDatasource(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/api/panel/datasource/${payload.datasource_id}`, payload)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		removeDatasource(ctx, { datasource_id }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/api/panel/datasource/${datasource_id}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		//获取指定数据源所有数据表
		fetchTables(ctx, { datasource_id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/api/panel/datasource/tables/${datasource_id}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		//获取指定数据源指定数据表所有字段
		fetchFields(ctx, { datasource_id, table }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/api/panel/datasource/fields/${datasource_id}/${table}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},

		// *===============================================---*
		// *--------- Api-查询 ---------------------------------------*
		// *===============================================---*
		fetchQueryList(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.get("/api/panel/query", { params: payload })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		addQuery(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post("/api/panel/query", payload)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		updateQuery(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/api/panel/query/${payload.query_id}`, payload)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		removeQuery(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/api/panel/query/${payload}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		//获取指定数据源所有数据表
		fetchQuery(ctx, { query_id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/api/panel/query/${query_id}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		//获取指定数据源所有数据表
		fetchQueryData(ctx, { query_id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/api/panel/query-data/${query_id}`)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
	},
};
