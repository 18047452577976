<template>
	<div id="scroll-settings" class="w-100 h-100">
		<b-form @change="update_store_options">
			<b-form-group label="监控字段">
				<v-select v-model="option.field" :clearable="false" :options="query_data.fields" placeholder="请选择监控字段" />
			</b-form-group>

			<b-form-group label="通知参数区间设定">
				<b-row>
					<b-col cols="5">
						<b-form-input v-model="option.range.info_start" placeholder="下限" type="number" />
					</b-col>
					<b-col cols="1">~</b-col>
					<b-col cols="6">
						<b-form-input v-model="option.range.info_end" placeholder="上限" type="number" />
					</b-col>
				</b-row>
			</b-form-group>
			<b-form-group label="警告参数区间设定">
				<b-row>
					<b-col cols="5">
						<b-form-input v-model="option.range.warning_start" placeholder="下限" type="number" />
					</b-col>
					<b-col cols="1">~</b-col>
					<b-col cols="6">
						<b-form-input v-model="option.range.warning_end" placeholder="上限" type="number" />
					</b-col>
				</b-row>
			</b-form-group>

			<b-form-group label="滚动间隔 (ms)">
				<b-form-input v-model="option.speed" type="number" />
			</b-form-group>
		</b-form>
	</div>
</template>

<script>
	import store from "@/store";
	import vSelect from "vue-select";

	import { computed, reactive, toRefs, watch } from "@vue/composition-api";

	export default {
		name: "ScrollSettings",
		components: {
			vSelect,
		},
		props: ["item"],
		setup(props) {
			const { item } = props;

			const event = reactive({
				option: item.options,
				query_data: computed(() => {
					const query_key = "query_" + item.query_id;
					let query_data = store.state["panel-group"]["query-data-list"][query_key];
					if (!(query_data && query_data.temp_data)) {
						query_data = Object.assign({}, { temp_data: [] });
					}
					return query_data;
				}),
			});
			watch(
				() => event.query_data,
				() => {
					update_store_options();
				}
			);
			//更新store的数据
			const update_store_options = () => {
				let options = store.state["panel-group"]["panel-modal"]["options"];
				options = Object.assign(options, event.option);

				store.commit("panel-group/UPDATE_PANEL_MODAL_OPTIONS", {
					...options,
				});
			};
			return {
				...toRefs(event),
				update_store_options,
			};
		},
	};
</script>
<style>
	#scroll-settings .input-group-append .input-group-text {
		padding-left: 0.5rem !important;
		padding-right: 0.5rem !important;
	}
</style>
