<template>
	<div id="table-settings">
		<app-collapse>
			<app-collapse-item title="表格参数配置">
				<b-form-group>
					<b-form-checkbox v-model="option.small">紧凑</b-form-checkbox>
					<b-form-checkbox v-model="option.striped">条纹</b-form-checkbox>
					<b-form-checkbox v-model="option.bordered">全边框</b-form-checkbox>
					<b-form-checkbox v-model="option.borderless">无边框</b-form-checkbox>
					<b-form-checkbox v-model="option.hover">Hover</b-form-checkbox>
					<b-form-checkbox v-model="option.fixed">等宽</b-form-checkbox>
				</b-form-group>
			</app-collapse-item>

			<app-collapse-item title="表头字段配置">
				<b-form-group>
					<div v-for="(fieldItem, fieldIndex) in fields" :key="'field-' + fieldIndex" class="position-relative">
						<label>{{ fieldItem.key }}</label>
						<b-input-group @change="fields_change(fieldIndex)">
							<b-input-group-prepend is-text>
								<feather-icon
									:icon="fieldItem.show ? 'EyeIcon' : 'EyeOffIcon'"
									class="cursor-pointer"
									@click="
										fieldItem.show = !fieldItem.show;
										fields_change(fieldIndex);
									"
								/>
							</b-input-group-prepend>
							<b-form-input v-model="fieldItem.label" placeholder="自定义字段名" />

							<b-input-group-append is-text>
								<feather-icon
									:class="!!fieldItem.sortable ? 'text-success' : ''"
									:icon="fieldItem.sortable ? 'BarChartIcon' : 'BarChart2Icon'"
									class="cursor-pointer"
									@click="
										fieldItem.sortable = !fieldItem.sortable;
										fields_change(fieldIndex);
									"
								/>
							</b-input-group-append>
						</b-input-group>
					</div>
				</b-form-group>
			</app-collapse-item>

			<app-collapse-item title="报警参数配置">
				<b-form-group class="position-relative">
					<b-form-checkbox :checked="alert.active" switch @change="alert_change">
						{{ !alert.active ? "关闭" : "启用" }}
					</b-form-checkbox>

					<template v-if="!!alert">
						<label>监控字段</label>
						<v-select v-model="alert.field" :clearable="false" :options="fields" class="rounded" placeholder="请选择监控字段" />

						<label>区间设定</label>
						<b-input-group>
							<b-form-input v-model="alert.lvl_1" placeholder="报警下限" type="number" />
							<b-form-input v-model="alert.lvl_2" placeholder="正常下限" type="number" />
							<b-form-input v-model="alert.lvl_3" placeholder="正常上限" type="number" />
							<b-form-input v-model="alert.lvl_4" placeholder="报警上限" type="number" />
						</b-input-group>
					</template>
				</b-form-group>
			</app-collapse-item>
		</app-collapse>
	</div>
</template>

<script>
	import store from "@/store";
	import Ripple from "vue-ripple-directive";
	import { computed, reactive, toRefs, watch } from "@vue/composition-api";
	import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
	import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
	import vSelect from "vue-select";
	import { BFormCheckbox } from "bootstrap-vue";

	export default {
		name: "TableSettings",
		directives: {
			Ripple,
		},
		components: {
			AppCollapse,
			AppCollapseItem,
			vSelect,
			BFormCheckbox,
		},
		props: ["item"],
		setup(props) {
			const { item } = props;
			const query_key = "query_" + item.query_id;
			const event = reactive({
				option: item.options.option ? item.options.option : {},
				fields: item.options.fields,
				scroll: item.options.scroll ? item.options.scroll : false,
				alert: item.options.alert,
				query_data: computed(() => {
					let query_data = store.state["panel-group"]["query-data-list"][query_key];
					if (!(query_data && query_data.temp_data)) {
						query_data = Object.assign(
							{},
							{
								temp_data: [],
								fields: [],
							}
						);
					}
					return query_data;
				}),
			});

			watch(
				() => event.query_data.fields,
				(value) => {
					fields_format(value);
					update_store_options();
				}
			);
			watch(event.option, (value) => {
				console.log("watch");
				update_store_options();
			});
			watch(
				() => event.fields,
				(value) => {
					update_store_options();
				}
			);
			watch(
				() => event.scroll,
				(value) => {
					update_store_options();
				}
			);
			watch(
				() => event.alert,
				(value) => {
					update_store_options();
				}
			);

			//表格字段整理
			const fields_format = (fields) => {
				let field_list = [];
				fields.map((field) => {
					field_list.push({
						key: field,
						label: field,
						sortable: false,
						show: true,
					});
				});
				event.fields = field_list;
			};

			//更新store的数据
			const update_store_options = () => {
				let options = store.state["panel-group"]["panel-modal"]["options"];
				options.option = event.option;
				options.fields = event.fields;
				options.scroll = event.scroll;
				options.alert = event.alert;
				store.commit("panel-group/UPDATE_PANEL_MODAL_OPTIONS", {
					...options,
				});
			};

			//字段配置改变
			const fields_change = (fieldIndex) => {
				update_store_options();
			};

			const alert_change = () => {
				event.alert.active = !event.alert.active;
				update_store_options();
			};

			const scroll_change = () => {
				event.scroll = !!event.scroll ? false : 100;
				update_store_options();
			};

			return {
				...toRefs(event),
				fields_change,
				alert_change,
				scroll_change,
			};
		},
	};
</script>
<style>
	#table-settings .custom-checkbox:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	#table-settings .custom-checkbox {
		margin: 0;
		width: 50%;
	}
</style>
