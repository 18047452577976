import {
	addEquipment,
	getAlarms,
	getEnergyCompareDataList,
	getEnergyDataList,
	getEnergyFilters,
	getEquipment,
	getEquipmentList,
	getEquipmentTree,
	getEquipMeterCodeList,
	getStatisticsNumbers,
	updateCommand,
	updateEquipment,
		updateEnergyData
} from "@/api/jsf35";

export default {
	namespaced: true,
	state: {
		active_model: "",
		system_type: "",
		equip_tree: [],
		equip_list: [],
		equip_list_current_data:{},
		equip_meter_code_list: [],
		equip_selected: null,
		equip_hovered: null,
		model_selected: null,
		model_homepage: null,
		dbId_selected: null,
		equip_key_selected: null,
		dbId_list: [],
		energy_filters: {},
	},
	getters: {},
	mutations: {
		setActiveModel(state, data) {
			state.active_model = data;
		},
		setSystemType(state, type) {
			state.system_type = type;
		},
		setEquipTree(state, data) {
			state.equip_tree = data;
		},
		setEquipList(state, data) {
			state.equip_list = data;
		},
		setEquipListCurrentData(state, data) {
			state.equip_list_current_data = data;
		},
		setEquipMeterCodeList(state, data) {
			state.equip_meter_code_list = data;
		},
		setEquipSelected(state, data) {
			state.equip_selected = data;
		},
		setEquipHovered(state, data) {
			state.equip_hovered = data;
		},
		setModelSelected(state, data) {
			state.model_selected = data;
		},
		setModelHomepage(state, data) {
			state.model_homepage = data;
		},
		setDbIdSelected(state, data) {
			state.dbId_selected = data;
		},
		setEquipKeySelected(state, data) {
			state.equip_key_selected = data;
		},
		setDbIdList(state, data) {
			state.dbId_list = data;
		},
		setEnergyFilters(state, data) {
			state.energy_filters = data;
		},
	},
	actions: {
		fetchStatisticsNumbers(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getStatisticsNumbers(queryParams)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
		fetchAlarms(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getAlarms(queryParams)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
		fetchEquipMeterCodeList(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getEquipMeterCodeList(queryParams)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
		fetchEquipmentList(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getEquipmentList(queryParams)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
		fetchEquipmentTree(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getEquipmentTree(queryParams)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
		fetchEquipment(ctx, id) {
			return new Promise((resolve, reject) => {
				getEquipment(id)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
		editEquipment(ctx, data) {
			return new Promise((resolve, reject) => {
				updateEquipment(data)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
		addEquipment(ctx, data) {
			return new Promise((resolve, reject) => {
				addEquipment(data)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
		submitCommand(ctx, data) {
			return new Promise((resolve, reject) => {
				updateCommand(data)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},

		fetchEnergyFilters(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getEnergyFilters(queryParams)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},

		fetchEnergyCompareDataList(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getEnergyCompareDataList(queryParams)
						.then((response) => resolve(response))
						.catch((error) => reject(error));
			});
		},
	},
};
