<template>
    <div>
        <statistic-card-with-area-chart
            v-if="option.type === 'area-chart'"
            :chart-data="option.data"
            :color="option.color"
            :icon="option.icon"
            :statistic="option.title"
            :statistic-title="option.text"
            class="h-100"
        />
        <statistic-card-with-line-chart
            v-else-if="option.type === 'line-chart'"
            :chart-data="option.data"
            :color="option.color"
            :icon="option.icon"
            :statistic="option.title"
            :statistic-title="option.text"
            class="h-100"
        />
        <statistic-card-horizontal
            v-else-if="option.type === 'horizontal'"
            :color="option.color"
            :icon="option.icon"
            :statistic="option.title"
            :statistic-title="option.text"
            class="h-100"
        />
        <statistic-card-vertical
            v-else
            :color="option.color"
            :icon="option.icon"
            :statistic="option.title"
            :statistic-title="option.text"
            class="h-100"
        />
    </div>
</template>

<script>
import store from "@/store";
import { computed, reactive, toRefs } from "@vue/composition-api";
import { kFormatter } from "@/@core/utils/filter";

export default {
    components: {
        StatisticCardVertical: () =>
            import(
                "@/@core/components/statistics-cards/StatisticCardVertical.vue"
            ),
        StatisticCardHorizontal: () =>
            import(
                "@/@core/components/statistics-cards/StatisticCardHorizontal.vue"
            ),
        StatisticCardWithAreaChart: () =>
            import(
                "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue"
            ),
        StatisticCardWithLineChart: () =>
            import(
                "@/@core/components/statistics-cards/StatisticCardWithLineChart.vue"
            ),
    },
    props: ["item"],
    setup(props) {
        const { item } = props;
        const event = reactive({
            option: computed(() => {
                return item.options;
            }),
            query_data: computed(() => {
                const query_key = "query_" + item.query_id;
                let query_data =
                    store.state["panel-group"]["query-data-list"][query_key];
                if (!(query_data && query_data.temp_data)) {
                    query_data = Object.assign({}, { temp_data: [] });
                }
                return query_data;
            }),
        });
        return {
            ...toRefs(event),
            kFormatter,
        };
    },
};
</script>
