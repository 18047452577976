<template>
	<section class="p-0">
		<div :id="containerId" class="h-100 w-100 no-drag" />
	</section>
</template>

<script>
	import store from "@/store";
	import { measureTextWidth, Mix } from "@antv/g2plot";
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { isObjectChanged } from "@/libs/utils/isObjectChanged";

	export default {
		name: "G2-Mix",
		props: ["item", "preview"],
		setup(props) {
			let { item, preview } = props;
			const query_key = "query_" + item.query_id;
			const event = reactive({
				containerId: `panel_container_${item.panel_id}` + (preview ? "_preview" : ""),
				chart: null,
				colors: computed(() => {
					return Object.values(store.state.appConfig.colors);
				}),
			});
			// const item_data = computed(() => {
			//   // console.log("item_data", props.item.data)
			//   return props.item.data;
			// });

			// const item_options = computed(() => {
			//   return item.options;
			// });

			// const item_query_data = computed(() => {
			//   return store.state["panel-group"]["query-data-list"][query_key];
			// });

			onMounted(() => {
				g2_init();
			});

			watch(
				() => store.state["panel-group"]["query-data-list"][query_key],
				(newValue, oldValue) => {
					if (!!newValue && isObjectChanged(newValue, oldValue)) {
						g2_init();
					}
				}
			);

			watch(
				() => props.item.data,
				(newValue, oldValue) => {
					if (newValue && isObjectChanged(newValue, oldValue)) {
						g2_init();
					}
				}
			);

			watch(
				() => props.item.options,
				(newValue, oldValue) => {
					if (newValue && isObjectChanged(newValue, oldValue)) {
						g2_init();
					}
				}
			);

			watch(
				() => store.state["panel-group"]["panel-modal"]["options"],
				(newValue, oldValue) => {
					if (preview && !!newValue && isObjectChanged(newValue, oldValue) && item.query_id) {
						g2_init();
					}
				}
			);

			//全图通用配置
			const g2_default_options = {
				autoFit: true,
				tooltip: {
					shared: true,
					showMarkers: false,
					showCrosshairs: true,
					offsetY: -50,
				},
				legend: {
					position: "top",
					itemName: { style: { fill: "#82868b" } },
				},
				theme: {
					colors10: event.colors,
					styleSheet: {
						fontFamily: "Avenir",
					},
				},
				interactions: [{ type: "element-selected" }, { type: "element-active" }],
			};
			//图层通用配置
			const plot_default_pie_options = {
				radius: 0.7,
				appendPadding: 10,
				label: {
					type: "outer",
					content: "{name}\n{value} ({percentage})",
					style: {
						fill: "#82868b",
						fontSize: 12,
					},
				},
				statistic: {
					title: {
						offsetY: -4,
						style: {
							fill: "#82868b",
						},
						customHtml: (container, view, datum) => {
							const { width, height } = container.getBoundingClientRect();
							const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
							const text = datum ? datum.type : "总计";
							return renderStatistic(d, text, { fontSize: 28 });
						},
					},
					content: {
						offsetY: 4,
						style: {
							fill: "#82868b",
							fontSize: 24,
						},
						customHtml: (container, view, datum, data) => {
							const { width } = container.getBoundingClientRect();

							const text = datum ? `${datum.value}` : `${data.reduce((r, d) => r + d.value, 0)}`;
							return renderStatistic(width, text, { fontSize: 24 });
						},
					},
				},
			};

			function renderStatistic(containerWidth, text, style) {
				const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
				const R = containerWidth / 2;
				// r^2 = (w / 2)^2 + (h - offsetY)^2
				let scale = 1;
				if (containerWidth < textWidth) {
					scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
				}
				const textStyleStr = `width:${containerWidth}px;color:#82868b`;
				return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : "inherit"};">${text}</div>`;
			}

			const plot_default_line_options = {
				isStack: true,
				slider: false,
				xAxis: {
					title: {
						text: "",
						autoRotate: true,
						style: {
							fill: "#82868b",
							fontSize: 12,
						},
					},
					label: {
						autoHide: {
							minGap: 20,
						},
						formatter: (text) => {
							return text.replaceAll(" ", "\n").replaceAll("00:00", "00").replaceAll(".000000", "");
						},
					},
					line: {
						style: {
							stroke: "#82868b",
							lineWidth: 1,
							opacity: 0.7,
						},
					},
				},
				yAxis: {
					title: {
						text: "",
						autoRotate: true,
						style: {
							fill: "#82868b",
							fontSize: 12,
						},
					},
					label: {
						autoHide: {
							minGap: 20,
						},
						formatter: (text) => {
							return text.replaceAll(" ", "\n");
						},
					},
					line: {
						style: {
							stroke: "#82868b",
							lineWidth: 1,
							opacity: 0.7,
						},
					},
					grid: {
						line: {
							style: {
								stroke: "#82868b",
								lineWidth: 1,
								lineDash: [2, 8],
								strokeOpacity: 0.7,
								shadowColor: "#82868b",
								shadowBlur: 10,
								shadowOffsetX: 5,
								shadowOffsetY: 5,
								cursor: "pointer",
							},
						},
					},
				},
			};
			/**
			 * 初始化图表
			 */
			const g2_init = () => {
				let containerId = event.containerId;
				let item_options = props.item.options;
				let _options = Object.assign(g2_default_options, JSON.parse(JSON.stringify(item_options)));
				//是否显示图例
				if (item_options.legend || item_options.legend === undefined) {
					_options.legend = {
						position: "top",
						itemName: { style: { fill: "#82868b" } },
					};
				} else {
					_options.legend = false;
				}

				// console.log('data',data)
				if (_options.plots) {
					_options.plots.map((plot) => {
						// console.log('plot', plot)
						if (!plot.region) {
							plot.region = {
								start: {
									x: 0,
									y: 0,
								},
								end: {
									x: 0.98,
									y: 1,
								},
							};
						}
						//数据处理
						let data = [];
						let item_query_data = store.state["panel-group"]["query-data-list"][query_key];
						let temp_data = plot.data || (item_query_data ? item_query_data.temp_data : []);
						// console.log('plot.data', plot.data)
						if (temp_data) {
							//折柱散点数据处理
							if (["line", "column", "area", "bar", "scatter"].includes(plot.type)) {
								data = temp_data;
								// let legend_list = []
								// let x_list = []
								// const legendField = plot.options.seriesField || plot.options.colorField
								// item_query_data.value.temp_data.map(item=>{
								// 	legend_list.push(item[legendField])
								// 	x_list.push(item[plot.options.xField])
								// })
								// legend_list = [...new Set(legend_list)]
								// x_list = [...new Set(x_list)]
								// console.log('legend_list',legend_list)
								// console.log('x_list',x_list)
								let options = plot_default_line_options;

								if (plot.options.xAxis === false) {
									options.xAxis = false;
								} else {
									let xAxis = plot.options.xAxis || options.xAxis;
									//x轴配置
									if (xAxis || xAxis === undefined) {
										options.xAxis.title.text = xAxis.title ? xAxis.title.text : "";
										options.xAxis.minLimit = xAxis.minLimit || xAxis.minLimit === 0 ? xAxis.minLimit : null;
										options.xAxis.maxLimit = xAxis.maxLimit || xAxis.maxLimit === 0 ? xAxis.maxLimit : null;
										options.xAxis.tickInterval = xAxis.tickInterval || xAxis.tickInterval === 0 ? xAxis.tickInterval : null;
									}
								}

								if (plot.options.yAxis === false) {
									options.yAxis = false;
								} else {
									let yAxis = plot.options.yAxis || options.yAxis;
									//y轴配置
									if (yAxis || yAxis === undefined) {
										options.yAxis.title.text = yAxis.title ? yAxis.title.text : "";
										options.yAxis.minLimit = yAxis.minLimit || yAxis.minLimit === 0 ? yAxis.minLimit : null;
										options.yAxis.maxLimit = yAxis.maxLimit || yAxis.maxLimit === 0 ? yAxis.maxLimit : null;
										options.yAxis.tickInterval = yAxis.tickInterval || yAxis.tickInterval === 0 ? yAxis.tickInterval : null;
									}
								}

								options.isStack = !!plot.options.isStack;
								plot.options = Object.assign(plot.options, options);
								//是否显示时间轴
								if (!!item_options.slider) {
									plot.options.slider = {
										start: 0,
										end: 1,
										handlerStyle: {
											height: 18,
										},
										textStyle: {
											fill: "#eeeeee",
											fillOpacity: 1,
											shadowColor: "black",
											shadowBlur: 1,
										},
										trendCfg: {
											lineStyle: {
												fillOpacity: 1,
											},
										},
									};
								} else {
									plot.options.slider = false;
								}
							}
							//饼图数据处理
							if (plot.type === "pie") {
								let group_sum_data = {};
								const colorField = plot.options.colorField;
								const angleField = plot.options.angleField;
								temp_data.map((item) => {
									if (!group_sum_data[item[colorField]]) {
										group_sum_data[item[colorField]] = {};
										group_sum_data[item[colorField]][colorField] = "";
										group_sum_data[item[colorField]][angleField] = 0;
									}
									group_sum_data[item[colorField]][colorField] = item[colorField];
									group_sum_data[item[colorField]][angleField] = group_sum_data[item[colorField]][angleField] + (item[angleField] || 0);
								});
								data = Object.values(group_sum_data);
								data.map((dataItem) => {
									dataItem[angleField] = Math.round(dataItem[angleField] * 100) / 100;
								});
								plot.options = Object.assign({ ...plot_default_pie_options }, { ...plot.options });
							}
						}
						plot.options.data = data;
					});
				}

				// console.log('plot',_options.plots)
				if (event.chart) {
					event.chart.destroy();
				}
				let chart = new Mix(containerId, _options);
				// console.log("options", _options);
				chart.render();
				event.chart = chart;
			};
			return {
				...toRefs(event),
			};
		},
	};
</script>

<style scoped></style>
