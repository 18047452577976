import useJwt from "@/libs/auth/jwt/useJwt";

const axios = useJwt.axiosIns;
/*
 * @Description: 获取用户列表
 * @Author: 张宇童
 * @Date: 2020/9/13 14:29
 */
export const getUserList = (params) => axios.get("/api/user/list/", params);
export const getProjectList = (params) => axios.get("/api/project/list/", params);
export const getUserDetail = (id) => axios.get(`/api/user/info/${id}/`);
export const addUserInfo = (data) => axios.post(`/api/register/`, data);
export const assignedProject = (data) => axios.post(`/api/user/attach_project/`, data);
export const changeUserRole = (data) => axios.patch(`/api/user/edit/role/`, data);
export const getRoleList = (params) => axios.get("/api/role/list", params);
export const updateUserBasicInfo = (data) => axios.patch(`/api/user/change/${data.user_id}/`, data);
export const changeUserPassword = (data) => axios.patch(`/api/user/change/password/`, data);
export const deleteUser = (id) => axios.delete(`/api/user/delete/${id}/`);
export const deleteProject = (id) => axios.delete(`/api/user/detach_project`, { data: id });
